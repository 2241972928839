import React from "react";

import Layout from "../../components/layout/index";
import SEO from "../../components/utils/seo";

import "./index.css";

const toggleQuestion = (e) => {
  if (e.target.nodeName === "H4") {
    e.target.classList.toggle("active");
    e.target.nextSibling.nextSibling.classList.toggle("show");
  } else if (e.target.nodeName === "svg") {
    e.target.parentNode.classList.toggle("active");
    e.target.parentNode.nextSibling.nextSibling.classList.toggle("show");
  } else {
    e.target.parentNode.parentNode.classList.toggle("active");
    e.target.parentNode.parentNode.nextSibling.nextSibling.classList.toggle(
      "show"
    );
  }
};

function contactoPage() {
  return (
    <Layout>
      <SEO title="Contacto"></SEO>
      <div className="container contact">
        <div>
          <h3>Contacto</h3>
          <div className="contact-links-container">
            <p>
              Envíanos un correo a{" "}
              <a href="mailto:emtepepan@gmail.com">emtepepan@gmail.com</a>
            </p>
            <p className="phone">
              Agenda tu cita o contáctanos a través de los siguientes números
              telefónicos:
              <a>5653-7795</a>,<a>8502-2887</a>,<a>7030-0386</a>
            </p>
            <p>
              Visita nuestra página de{" "}
              <a
                href="https://www.facebook.com/EspecialidadesMedicasTepepan"
                target="blank"
              >
                Facebook
              </a>
            </p>
          </div>
          <h3>Visítanos</h3>
          <a
            className="waze"
            href="https://waze.com/ul/h9g3t8jezq"
            target="_blank"
          >
            <img src="https://www.waze.com/livemap3/assets/app-driver-6e3f6f8253316b69093e0db484bc3d9b.svg" />
            Abrir dirección en Waze
          </a>
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=especialidades%20medicas%20tepepan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
              <a href="https://www.maps-erstellen.de"></a>
            </div>
          </div>
        </div>
        <div className="faq">
          <h3>Preguntas frecuentes</h3>
          <p>
            Por favor busca tu duda en nuestro FAQ antes de mandar un correo.
          </p>
          <hr size="1"></hr>
          <div className="faq-container">
            <div>
              <h4 onClick={toggleQuestion}>
                <svg
                  className="arrow-down"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  />
                </svg>
                <svg
                  className="arrow-up"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M8.7 14.7a1 1 0 0 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 11.42l-3.3 3.3z"
                  />
                </svg>
                Citas
              </h4>
              <hr size="1"></hr>
              <ul>
                <li>
                  <h5>¿Cómo puedo agendar?</h5>
                  <ol>
                    <li> Llamando a los números en la sección de contacto.</li>
                    <li>
                      Mediante un mensaje directo a nuestra cuenta de Facebook.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
            <div>
              <h4 onClick={toggleQuestion}>
                <svg
                  className="arrow-down"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  />
                </svg>
                <svg
                  className="arrow-up"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M8.7 14.7a1 1 0 0 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 11.42l-3.3 3.3z"
                  />
                </svg>
                Pago
              </h4>
              <hr size="1"></hr>
              <ul>
                <li>
                  <h5>¿Qué métodos de pago aceptan?</h5>
                  <p>
                    Aceptamos pagos en eféctivo y con tarjeta de crédito y
                    débito con un cargo extra del 3%.
                    <span className="note">
                      <strong>NOTA: </strong>No todos los médicos aceptan
                      tarjeta.
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h4 onClick={toggleQuestion}>
                <svg
                  className="arrow-down"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  />
                </svg>
                <svg
                  className="arrow-up"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    class="heroicon-ui"
                    d="M8.7 14.7a1 1 0 0 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 11.42l-3.3 3.3z"
                  />
                </svg>
                Facturación
              </h4>
              <hr size="1"></hr>
              <ul>
                <li>
                  <h5>¿Cómo es el proceso de facturación?</h5>
                  <p>
                    Los médicos se encargan junto con sus contadores de realizar
                    las facturas pertinentes. La institución no emite factura
                    por consulta. Pregunte a su médico la forma de factura.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default contactoPage;
